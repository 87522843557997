$black: #000000;

$font-color: #6d6d6d !default;
$h-color: #4b4b4b !default;
$headings-color: #4b4b4b !default;

$primary:       #ff6600ff !default;
$secondary:     #4d4d4df0 !default;

$primary-color: $primary;

// Gradient colors
$primary-gradient: #ff6600ff !default;
$primary-gradient-state: #4d4d4df0 !default;

$blur-border-radius-rounded:    10px !default;

// text gradient
$text-gradient-bg-primary:    linear-gradient(310deg, $primary-gradient, $primary-gradient-state) !default;


$border-color: $primary;
$border-size: 2rem;
$navbar-dark-background: rgba(215, 215, 215, 0.6);
$navbar-dark-color: rgb(100, 100, 100);

//$navbar-dark-background: rgba(255, 255, 255, 0.4);
//$navbar-dark-color:  rgb(10, 10, 10);

$border-color-light: $primary;
$navbar-light-background: rgba(215, 215, 215, 0.8);
$navbar-light-color: rgb(100, 100, 100);

$navbar-brand-img-size: 3rem;
$navbar-brand-img-size-small: 2rem;

$navbar-brand-right-img-size: 3rem;
$navbar-brand-right-img-size-small: 2rem;


$dropdown-border-radius: 0;
$dropdown-inner-border-radius: 0;
//$dropdown-bg: rgba(255,255,255,0)
